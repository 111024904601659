// Global styling sheet

@import './variables';

// Global Styles
body {
    margin: 0;
    font-family: $primary-font;
    color: $grey;
    background-color: $primary-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .vis-tooltip {
    position: absolute;
  }
