// Component styling sheet
// Note: where there are nested classes, it is likely they are being used to override the bootstrap classes that have been inherited
@import './variables';

// Global classes - often these will be specified with high importance, this is to take priority over default bootstrap styling
body, html {
  overflow-x: hidden;
}


// hoppa logo has been converted to a font

@font-face {
  font-family: 'LogoAsFont';
  src: url('fonts/logo_as_font.eot'); /* IE9 Compat Modes */
  src: url('fonts/logo_as_font.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/logo_as_font.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/logo_as_font.woff') format('woff'), /* Modern Browsers */
       url('fonts/logo_as_font.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/logo_as_font.svg#LogoAsFont') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

.icon-logo:before {
  content: '\e900'; /* Replace with the correct Unicode value */
  font-family: 'LogoAsFont';
}


* {
  font-family: $primary-font !important;
  box-sizing: border-box
}

article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary {
  display: block
}

.page-wrapper {
  background-color: transparent;
  position: relative;
  overflow: visible;
}

.page-wrapper.fix_height {
  height: 12%;
  max-height: 12%;
  object-fit: none;
  overflow: hidden
}

.main-wrapper.fix_height {
  height: 100%;
  max-height: 100%;
  object-fit: none;
  position: static;
  overflow: hidden
}

.home-hero_section {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-detail_section {
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.inset-card {
  margin-top: 2rem !important;
  margin-bottom: 0.5rem;
}

// .panel-m.transparent {
//   -webkit-backdrop-filter: blur(16px);
//   backdrop-filter: blur(16px);
//   background-color: rgba(255, 255, 255, 0);
// }


.panel-m {
  padding: 0;
  border-radius: 10px;
  background-color: none;
  color: $white;
  // display: block;
  // position: relative;
  // overflow: hidden;
}


.floating-panel-2 {
  z-index: 10;
  width: 300px;
  // margin-left: auto;
  // margin-right: auto;
  // position: relative;
}

@media  screen and (max-width:1400px) {
  .floating-panel-2 {
    width: 350px;
    max-width: 100%
  }
}

@media  screen and (max-width:1000px) {
  .floating-panel-2 {
    width: 800px;
    max-width: 100%
  }
}


.inset-figure {
  font-family: Tt Firs Neue, sans-serif;
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
}

.case-study-container {
  flex-wrap: wrap;
}

.case-study {
  padding:0.7rem;
  margin: 0rem;
  height: 100% !important;

  a {
  text-decoration: none;
  color: inherit;
  } 
    /* Reset text styling for children of <a> element */
    a *,
    a *:before,
    a *:after {
      color: inherit; /* Inherit color from parent */
      text-decoration: inherit; /* Inherit text-decoration from parent */
    }

  .card-img-top {
    border: solid 1px lightgrey;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    overflow: hidden;
  }

  .card-footer {
    background-color: transparent;
    padding-right: 0.2rem;
    padding-bottom: 0;
  }
  
  .circle-icon {
    color: $secondary-color;
  }

  a:hover {
    .circle {
      // box-shadow: rgba(80, 63, 205, 0.5) 0 1px 15px;
      // background-image: linear-gradient(to right, $gradient-colors-btn-tertiary);
      .circle-icon {
        transform: translateX(0);
      }
    }
  }
}

.MuiCard-root:hover {
  .circle {
    // box-shadow: rgba(80, 63, 205, 0.5) 0 1px 15px;
    // background-image: linear-gradient(to right, $gradient-colors-btn-tertiary);
    .circle-icon {
      transform: translateX(0);
    }
  }
}

.fullscreen-modal {
  //display: flex;
  justify-content: center;
  align-items: center; /* Center both horizontally and vertically */
}

.fullscreen-modal .modal-dialog {
  //display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  //margin: auto;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
}

.fullscreen-modal .modal-content {
  background-color: transparent;
  border: none;
  
}

.fullscreen-gif {
  display: block;
  justify-content: center;
  width: 100%;
  height: auto;
  max-height: 90%;
  max-width: 90%;
  //object-fit: contain;
  border-radius: 15px;
  margin: auto;
}


.case-study-card-button {
  display: flex;
  justify-content: end;
}


.circle {
  border: 1px solid $secondary-color;
  border-radius: 100%;
  flex: none;
  justify-content: flex-start;
  align-items: center;
  width: 40px;
  height: 40px;
  transition: $tertiary-color .3s cubic-bezier(.215, .61, .355, 1);
  display: flex;
  position: relative;
  overflow: hidden;
}

.circle-static {
  border: 1px solid $secondary-color;
  border-radius: 100%;
  flex: none;
  justify-content: flex-start;
  align-items: center;
  width: 48px;
  height: 48px;
  transition: $tertiary-color .3s cubic-bezier(.215, .61, .355, 1);
  display: flex;
  position: relative;
  overflow: hidden;
}

.circle-icon {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: transform .3s cubic-bezier(.215, .61, .355, 1);
  display: flex;
  transform: translate(-100%);
  color: $secondary-color;
}

.circle-icon-static {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  color: $secondary-color;
}

.background-placeholder {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #bcc0f9; /* Plain background color */
  overflow: hidden;
  z-index: -10;
}

.background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.navigation_menu-icon {
  display: none;
  cursor: pointer;
  color: black;
  background-color: transparent;
  align-content: center;
}

.navigation_menu-pulldown {
  position: relative !important;
  margin-top: 50px !important;
  display: none;
}

.navigation_menu-pulldown-item {
  padding-top: 1rem;
  margin-left: 4% !important;
  font-size: 1.5rem !important;
  color: $secondary-color !important;
}
.navigation_menu-pulldown-item:hover {
  text-decoration: underline;
  cursor: pointer;
}

.navigation_menu-pulldown-button {
  margin-left: 4%;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.banner {
  background-color: $quaternary-color; /* White background for the banner */  
  color: $white;
  top: 0;                   /* Align the banner to the top */
  left: 0;
  width: 100%;              /* Make the banner span the full width of the page */
  z-index: 1000;            /* Ensure the banner stays on top of other content */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for separation */
  padding: 10px 0;
  text-align: center;       /* Center the text */
  border-bottom: 0.5px solid #000;  /* Border with 2px width, solid style, and black color */

}

.banner-mobile {
  background-color: $quaternary-color; /* White background for the banner */  
  color: $white;
  top: 0;                   /* Align the banner to the top */
  left: 0;
  width: 100%;              /* Make the banner span the full width of the page */
  z-index: 1000;            /* Ensure the banner stays on top of other content */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for separation */
  padding: 10px 0;
  text-align: center;       /* Center the text */
  border-bottom: 0.5px solid #000;  /* Border with 2px width, solid style, and black color */

}

@media screen and (max-width: 991px) {
  .navigation_component {
    background-color: white !important;
    border-bottom: 1px solid black !important;
  }

  .buttontext {
    max-width: 5vw !important;
  }

  .navigation-wrap {
    border: none !important;
    background-color: white !important;
  }

  .logo {
    color: $secondary-color !important;
  }

  .navigation-link {
    color: $secondary-color !important;
  }

  .navigation_menu-icon {
      display: block;
      cursor: pointer;
      color: $secondary-color;
    }

    .navigation_menu-pulldown {
      display:block;
    }

  .navigation_menu-wrap {
    display: none !important;
  }

  .navigation_button-wrapper {
    display: none !important;
  }
}

.padding-xhuge {
  padding-top: 7rem;
  padding-bottom: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.padding-huge {
  padding-top: 3rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.padding-medium {
  padding-top: 2rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.product-text-img_grid {
  grid-column-gap: 2.5rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.product-slider-image-3d {
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 0.5rem;
}

.btn-whyhoppa-xs {
  display: none !important; // hide button on full size screen
}

.benefits-card_grid {
  z-index: 1;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.benefits-card_grid .row {
  display: flex;
}

.benefits-card_grid .card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

 
 /* === range theme and appearance === */
 input[type="range"] {
   font-size: 1.5rem;
   width: 100%;
 }
 
 input[type="range"] {
   --thumb-height: 1.125em;
   --track-height: 0.125em;
   --track-color: rgba(0, 0, 0, 0.1);
   --brightness-hover: 180%;
   --brightness-down: 80%;
   --clip-edges: 0.125em;
 }
 
 input[type="range"].win10-thumb {
   color: #2b2d42;
 
   --thumb-height: 1.375em;
   --thumb-width: 0.5em;
   --clip-edges: 0.0125em;
 }
 
 input[type="range"].win10-thumb {
  color: $secondary-color;
}
 
 /* === range commons === */
 input[type="range"] {
   position: relative;
   background: #fff0;
   overflow: hidden;
 }
 
 input[type="range"]:active {
   cursor: grabbing;
 }
 
 /* === WebKit specific styles === */
 input[type="range"],
 input[type="range"]::-webkit-slider-runnable-track,
 input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none;
   transition: all ease 100ms;
   height: var(--thumb-height);
 }
 
 input[type="range"]::-webkit-slider-runnable-track,
 input[type="range"]::-webkit-slider-thumb {
   position: relative;
 }
 
 input[type="range"]::-webkit-slider-thumb {
   --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
   --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
   --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
   --clip-further: calc(100% + 1px);
   --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
     100vmax currentColor;
 
   width: var(--thumb-width, var(--thumb-height));
   background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
     50% calc(var(--track-height) + 1px);
   background-color: currentColor;
   box-shadow: var(--box-fill);
   border-radius: var(--thumb-width, var(--thumb-height));
 
   filter: brightness(100%);
   clip-path: polygon(
     100% -1px,
     var(--clip-edges) -1px,
     0 var(--clip-top),
     -100vmax var(--clip-top),
     -100vmax var(--clip-bottom),
     0 var(--clip-bottom),
     var(--clip-edges) 100%,
     var(--clip-further) var(--clip-further)
   );
 }
 
 input[type="range"]:hover::-webkit-slider-thumb {
   filter: brightness(var(--brightness-hover));
   cursor: grab;
 }
 
 input[type="range"]:active::-webkit-slider-thumb {
   filter: brightness(var(--brightness-down));
   cursor: grabbing;
 }
 
 input[type="range"]::-webkit-slider-runnable-track {
   background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
     100% calc(var(--track-height) + 1px);
 }
 
 input[type="range"]:disabled::-webkit-slider-thumb {
   cursor: not-allowed;
 }
 
 /* === Firefox specific styles === */
 input[type="range"],
 input[type="range"]::-moz-range-track,
 input[type="range"]::-moz-range-thumb {
   appearance: none;
   transition: all ease 100ms;
   height: var(--thumb-height);
 }
 
 input[type="range"]::-moz-range-track,
 input[type="range"]::-moz-range-thumb,
 input[type="range"]::-moz-range-progress {
   background: #fff0;
 }
 
 input[type="range"]::-moz-range-thumb {
   background: currentColor;
   border: 0;
   width: var(--thumb-width, var(--thumb-height));
   border-radius: var(--thumb-width, var(--thumb-height));
   cursor: grab;
 }
 
 input[type="range"]:active::-moz-range-thumb {
   cursor: grabbing;
 }
 
 input[type="range"]::-moz-range-track {
   width: 100%;
   background: var(--track-color);
 }
 
 input[type="range"]::-moz-range-progress {
   appearance: none;
   background: currentColor;
   transition-delay: 30ms;
 }
 
 input[type="range"]::-moz-range-track,
 input[type="range"]::-moz-range-progress {
   height: calc(var(--track-height) + 1px);
   border-radius: var(--track-height);
 }
 
 input[type="range"]::-moz-range-thumb,
 input[type="range"]::-moz-range-progress {
   filter: brightness(100%);
 }
 
 input[type="range"]:hover::-moz-range-thumb,
 input[type="range"]:hover::-moz-range-progress {
   filter: brightness(var(--brightness-hover));
 }
 
 input[type="range"]:active::-moz-range-thumb,
 input[type="range"]:active::-moz-range-progress {
   filter: brightness(var(--brightness-down));
 }
 
 input[type="range"]:disabled::-moz-range-thumb {
   cursor: not-allowed;
 }
 
.input-number {
  font-size: 2rem !important;
  text-align: center;
  font-weight: bold !important;
}

.input-number_units {
  font-size: 2rem !important;
  text-align: center;

}

.input-number_box {
  margin-bottom: 1rem;
  margin-left: 0;
  display: flex;
  align-items: center; 
  width: 250px;
}

.mailing-list-card {
  background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      height: 55%;
      padding: 2rem;
      color: black;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.say-hello-card {
  background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      height: 30%;
      padding: 2rem;
      padding-bottom: 5rem;
      margin-bottom: 1rem;
      color: black;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.request-demo-card {
  background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      height: 96%;
      padding: 2rem;
      color: black;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.triangle-background {
  background-image: linear-gradient(to right, $gradient-colors-contact);
  background-color: $light-purple;
  clip-path: polygon(0 0, 100% 10%, 100% 90%, 0% 100%);
}

footer {
  margin-top: 3rem;
  min-height: 100px;
  background: $quaternary-color;
  padding: 2rem;
  padding-bottom: 2rem;
  color: white !important;

}

.logo-footer {
  color: $white;
}

.footer-link-header {
  color: $light-purple;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 1.1rem;
  font-weight: 250;
  text-decoration: none;
}

.footer-link {
  color: white;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .2s;
}

.footer-link:hover {
  color: $tertiary-color;  
}

.enabled:hover {
  cursor: pointer;
}

.line-divider {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  height: 1px;
  background-color: white;
}

@media screen and (min-width: 479px) {
  .product-slider-image-3d {
    transform: perspective(1500px) rotateY(-15deg);
    transition: transform 1s ease 0s;

    &:hover {
      transform: perspective(3000px) rotateY(5deg);
    }

  }

  .custom-modal-mobile-header {
    display: none;
  }

  

}

@media screen and (max-width:479px) {
.product-text-img_grid{
  grid-template-columns: 1fr;
}
.padding-xhuge {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

footer {
  padding: 2rem;
}

.custom-modal-title {
  font-size: 1.5rem !important;
  padding: 1.5rem !important;
}

.custom-modal-image {
  display: none;
}

.banner {
  display: none;
}

.custom-modal-title {
  display: none;
}

.custom-modal-content-container {
  width: 100% !important;
}

.overflow-container {
  margin-top: 1rem;
}

.error-page-text {
  text-align: center;
}

.full-width-error-section {
  align-items: top !important;
  //min-height: 80vh !important;
}

}

.modal-dialog {
  overflow:hidden;
}

.custom-input {
  display: block;
  background-color: transparent;
  height: 50px;
  width: 100%;
  margin: 0 auto;
  padding-top: 0.5rem;
  border: none;
  overflow: visible;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  -webkit-transition: .5s;
  transition: .5s;

  &::placeholder {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: .5s;
    transition: .5s;
    color: black;
  }

  &:hover,
  &:focus,
  &:active:focus {
    color: $secondary-color;
    outline: none;
    border-bottom: 1px solid $secondary-color;

    &::placeholder {
      color: $secondary-color;
      position: relative;
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);

    }
  }
}

.custom-input-validated {
  &:invalid {
    color: #dc3545 !important;
    &::placeholder {
      color: #dc3545 !important;
    }
  }

}

.modal-content {
    max-height: 90vh;
  }


.custom-modal-content {
  padding: 0;
  margin: 0;
  display: inline-flex;
  overflow:hidden;
  border-radius: inherit;
}

.custom-modal-content-container {
  width: 50%;
  margin:0;
  padding: 2rem;
  overflow: hidden;
  display: inline-flex;
  position: relative;  
  align-items: center;
}

.custom-modal-image {
  overflow:hidden;
  z-index: 0;
  position: relative;
  object-fit: cover;
  width: 50%;
  // right: 50%;
  opacity: 0.5;
}

.custom-modal-title {
  padding: 3rem;
  font-size: 3rem;
  width: 50%;
  color: $secondary-color;
  z-index: 1;
  position: absolute;
}

.custom-modal-close-btn {
  position: absolute !important;
  top: 10px;
  right: 10px;
  background: none;
}

.btn-link {
  min-width: 5px !important;
}

.overflow-container {
  display:flex;
  min-width: 200%;
}

.form-container {
  width: 50%;
  transition: transform 0.3s ease;
}

.slide-in-left {
  transform: translateX(0);
  padding-right: 2rem;
  overflow:visible
}

.slide-out-left {
  transform: translateX(-100%);
  padding-right: 2rem;
  overflow: hidden;
}

.slide-out-right {
  transform: translateX(0%);
  padding-left: 2rem;
  overflow: hidden;
}

.slide-in-right {
  transform: translateX(-100%);
  padding-right: 2rem;
  overflow: visible;
}

// Silly rule for single line card titles...
@media screen and (max-width: 1400px) and (min-width: 991px){
  .case-study {
  h5 {
    min-height: 48px !important;
  }
}
}

@media screen and (max-width:767px) {


  .product-text-img_grid {
    grid-template-columns: 1fr;
  }

  .btn-whyhoppa-xs {
    display: block !important; // display button on mobile screen
  }

  .btn-whyhoppa-lg {
    display: none !important; // hide button on mobile screen
  }


  .padding-xhuge {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .benefits-card_grid .card {
    padding: 0.8rem;
}

  .benefits-card_grid .card .card-title{
      font-size: 1.2rem;
  }
  .benefits-card_grid .card .card-text{
    font-size: 1rem;
}

.total-price-figure {
  font-size: 100px !important;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}


.custom-modal-title {
  font-size: 2rem;
}

}

@media screen and (max-width:991px) {
  .product-text-img_grid {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
}

.btn-whyhoppa-xs {
  display: block !important; // display button on mobile screen
}

.btn {
  .buttontext {
    max-width: 5vw !important;
  }
}

.btn-whyhoppa-lg {
  display: none !important; // hide button on mobile screen
}
}

.background-video-banner {
  height: 100%;
  overflow: hidden;
  position: relative;
  
}

.background-video-banner video {
  position: absolute;
  z-index: -100;
  object-fit: cover;
  width: 100vw;  
  height: 100%;
  // overflow: hidden;
}

// .video-background::before {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: -1;
//   background-color: rgba(150, 250, 255, 0.079); /* Adjust color and opacity as needed */
// }


// BOOTSTRAP COMPONENT OVERRIDES
.no-border-radius {
    border-radius: 0 !important; /* Set border-radius to 0 to remove the rounded corners */
}

.no-border {
    border: none !important;
}

.no-margin {
    margin: 0 !important;
}

.no-background {
    background-color: transparent !important;
}

.hidden {
    display: none;
  }

  // FONT STYLING
  .white-font {
    color: $primary-color !important;
  }

  .blue-font {
  color: $secondary-color !important;
}

.highlighted-text {
  background: linear-gradient(to top, rgba(114, 114, 255, 0) 0%, rgba(79, 79, 251, 0.5) 50%, rgba(0, 0, 255, 0) 100%);
  color: white;
  padding: 0.2em; /* Optional: Adjust padding for better highlight visibility */
  border-radius: 1em; /* Optional: Adds rounded corners for a smoother look */
}

.orange-font {
  color: $tertiary-color !important;
}

.purple-font {
  color: $quaternary-color !important;
}

.red-font {
  color: $red;
  text-shadow: rgb(255, 145, 0) 0 1px 1px;
}

.green-font {
  color: #70e000;
  // text-shadow: rgb(30, 109, 10) 1.8px 1px 2px;
}

.light-grey-font {
  color: $light-grey;
}

.light-purple-font {
  color: $light-purple;
}

.gradient-font {
  background: linear-gradient(to right, $bright-green, $white);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.large-font {
  font-size: 32px;
  line-height: 100%;

}

.xlarge-font {
  font-size: 2.8rem;
  line-height: 120%;
}

.medium-font {
  font-size:24px;
}


.highlight-text {
  //display: inline-block;
  animation: glowingBorderAnimation 5s infinite;
  font-size: 3.0rem;
}

.highlight-text-med {
  //display: inline-block;
  animation: glowingBorderAnimation 5s infinite;
  //font-size: 1.3rem;
}

.bold-statement{
  color: white;
  margin-bottom: 1rem;
}

.v1 {
  border-left: 0.5px solid $secondary-color;
  height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media (max-width: 1400px) {
  .highlight-text {
    font-size: 2.7rem !important;
  }

  .typing-text {
    font-size: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom:1rem;
  }
  
}

.colored-underline {
  text-decoration: underline;
  text-decoration-color: #00aaff;
  text-decoration-thickness: 4px;
}

.hidden-on-mobile {
  display: none; /* Initially hide the component on mobile */
}

@media (min-width: 768px) {
  .hidden-on-mobile {
    display: block; /* Show the component on non-small screens */
  }
}

.modal-session-commit{

  .modal-dialog {
    max-height: 90vh;
  }

  .modal-content {
    height: 100%;
    padding: 2rem;
  }
}

.mmodal-session-commit-row {
  display: flex;
  align-content: center;
  justify-items: center;
}

.icon-innercontainer {
  padding: 1dvb;
  border-radius: 100%; /* Make it a circle */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3DDBBE;
}

.icon-wrapper {
  display: inline-flex; /* Use flexbox to make the wrapper shrink-to-fit */
}

.icon-outercontainer {
  padding: 1.5rem;
  border-radius: 100%; /* Make it a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #C9ECE6;
}

.icon-outercontainer:before {
  content: ""; /* Create a pseudo-element */
  display: block; /* Make it a block element */
  padding-top: 100%; /* Set the padding top to 100% of the width (creates a square aspect ratio) */
}

.modal-session-commit-video {
  height: 100%;
  // transform: translateY(-2.5%)
}


@keyframes glowingBorderAnimation {
  0% {
    text-shadow: 0 0 10px $quaternary-color;
  }
  50% {
    text-shadow: 0 0 10px $secondary-color;
  }

  100% {
    text-shadow: 0 0 10px $quaternary-color;
  }
}

// BACKGROUND COLORS
.white-background {
  background-color: $white !important;
}

.blue-background {
  background-color: $secondary-color !important;
}

.orange-background {
  background-color: $tertiary-color !important;
}

.green-background {
  background-color: $bright-green !important;
}

.background-fade-purple-sunburst {
  //background: linear-gradient(to bottom, #d8fefa88, #d5edfd9e) !important;
  //background-color: #BEC3FF;
  //color: white !important;
  //background-color: $primary-color;
  //background: linear-gradient(to left, #feef8e, #fe9e7b,  #a964fe);
  background: white;
  border-radius: 8px;
  //border: 1px solid $quaternary-color;
  box-shadow: 2px 2px 3px #9c9c9c;
}

.background-fade-purple-to-white {
  //background: $chilled-green;
  background: linear-gradient(to bottom, $chilled-purple, $primary-color);
}

.background-fade-white-to-green {
  //background: $chilled-green;
  background: linear-gradient(to bottom, $primary-color, $chilled-green);
}



// BORDERS

.white-box-border {
  border-color: $primary-color !important;
  border-radius: 5px !important;
  border-width: 3px !important;
}

// BUTTON STYLING
.btn {
  font-size: 1rem !important;
  padding: 0.1rem 1rem !important;
  border-radius: 50px !important;
  margin: 5px !important;
  height: 40px !important;
  min-width: 120px;
  vertical-align: text-bottom !important;
  border: 1px solid !important;
  text-transform: none !important; 
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .buttontext {
    max-width: 11vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.btn-link {
  border: none !important;
  height: auto !important;
}

.btn-primary {
    background-image: linear-gradient(to right, $gradient-colors-btn-primary);
    color:$white !important;
    border-color: grey !important;
}

.btn-primary:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 15px;
  transition-duration: .1s;
}

.btn-primary:active {
  background-image: none !important;
  background-color: $white !important;
}

.btn-secondary {
  border-color: $secondary-color !important;
  color: $secondary-color !important;
  background-color: $white !important;
  background-image: none !important;
}

.btn-ellipsis {
  border: none !important;  // Remove the border if no circle is needed
  background-color: transparent !important;  // Transparent background
  display: flex !important;  // Flexbox to center icon
  justify-content: center !important;
  align-items: center !important;
  font-size: 25px !important;  // Adjust the size of the icon
  color: #d3d3d3 !important;  // Light grey color for the ellipsis icon
  width: 50px;
  
  &:hover {
    color: $secondary-color !important; // Change color on hover
  }
}

.dropdown-menu {
  cursor: auto;
}

.btn-ellipsis::after {
  content: none !important;  /* Removes dropdown arrow */
}

.btn-white-transp {
  border-color: white !important;
  color: white !important;
  background-color: none !important;
}

.btn-secondary:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 15px;
  transition-duration: .1s;
}

.btn-secondary:active {
  background-image: linear-gradient(to right, $gradient-colors-btn-primary);
}

.btn-secondary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  box-shadow: none;
}

.btn-excel {
  background-color: #008000 !important;
  border-color: green  !important;
  background-image: none;
  color: white !important;
}

.btn-success-run {
  background-image: linear-gradient(to right, $gradient-colors-btn-tertiary);
  color: $white !important;
    border-radius: 50px !important;
  padding: 0.4rem 1.6rem !important;
  margin: 5px;
    border-width: 1px !important;
    min-width: 120px;
}

.btn-success-run:hover {
  box-shadow: $success-shadow 0 1px 15px;
  transition-duration: .1s;
}

.btn-tertiary {
  background-image: linear-gradient(to right, $gradient-colors-btn-tertiary);
  color: $white !important;
  border: 1px solid slategray !important;
}

  .btn-tertiary:hover {
    cursor: pointer;
    box-shadow: rgba(80, 63, 205, 0.5) 0px 0px 10px !important;
    transition-duration: .1s;
  }

  .btn-tertiary:active {
    background-image: none !important;
    background-color: $white !important;
    color: $tertiary-color !important;
  }

  .card-template {
    border-radius: 20px !important;
    min-height: 250px !important;
    color: $secondary-color !important;
    background-color: $white;
  }

  .card-template:hover {
    transition-duration: .1s;
    cursor: pointer; 
    box-shadow: $quaternary-color 0px 0px 5px;
  }

.card-template-default-selected {
  color: $secondary-color !important;
  background-color: $white;
  border: 2px solid $secondary-color !important;
}

.card-template-custom:hover {
  box-shadow: $secondary-color 0px 0px 5px;;
}

.card-template-custom {
  color: $white !important;
  background-image: linear-gradient(to right, $gradient-colors-btn-primary);
  border-radius: 20px !important;
}

.card-template-custom-selected {
  color: $white !important;
  background-image: linear-gradient(to right, $gradient-colors-btn-primary);
  border-radius: 20px !important;
  border: 2px solid $tertiary-color !important;
}

.card-template-disabled:hover {
  cursor: not-allowed;
}

// .icon-style {
//     color: $grey !important;
// }

.icon-background-style {
  color: $background-color
}

.icon-foreground-style {
  color: $secondary-color;
}

.bg-information {
  background-color: $tertiary-color !important;
}

.chip-style {
  background-color: $background-color !important;
  color: black !important;
  font-family: $primary-font !important;
  border-color: $grey !important;
}

.tag-selected{
  background-image: linear-gradient(to right, $gradient-colors-btn-primary);
  color: $white !important;
}

.MuiChip-deleteIcon {
  color: $white !important;
}

.spinner-style {
  color: $secondary-color !important;
  position:relative;
  justify-self: center;
  align-self: middle;
}

.popup-style {
  font-size: 20px !important;
}
  
// HeaderBar Styling
.header-bar {
    background-color: transparent;
    color: $white;
    font-family: $primary-font;
    .navbar-nav .nav-link {
        color: $white;
    }
}

.navbar-brand {
    color: $white !important;
    font-size: 35px !important;
    padding-bottom: 8px !important;
}

.navbar-text {
  color: white !important;
}

// Home styling

.logo-link {
  text-decoration: none;
}

.logo {
    font-size:2.5rem;
    color: white;
    position: relative;
    margin-right:3vw;
    //text-shadow: 0 0 10px $primary-color
    // z-index: 1000; /* Adjust the z-index as needed to ensure it appears above other components */
  }

  .logo-blue {
    font-size:2.5rem;
    color: $secondary-color;
    position: relative;
    margin-right:3vw;
    //text-shadow: 0 0 10px $primary-color
    // z-index: 1000; /* Adjust the z-index as needed to ensure it appears above other components */
  }

.navigation_component {
  z-index: 100;
  background-color: transparent;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.navigation-wrap {
  height:80px;
  width: 90%;
  max-width: 1320px;
  background-color: transparent;
  border: 1.5px solid transparent;
  border-radius: .5rem;
  justify-self: center;
  padding-left: 2%;
  padding-right: 2%;
  transition: box-shadow .2s;
  margin-top: 0.5%;
  box-shadow: 7px 7px 40px -20px transparent;
}

.container-navigation {
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  flex-grow: 1;
}

.navigation_link-wrapper {
  width: 60%;
  min-height: 80px;
  align-items: center;
  display: flex;
}

.navigation_menu-wrap {
  width: 100%;
  height: 100%;
  color: #2b2c4f;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  position: static;
}

.navigation_link-wrapper {
  width: 60%;
  min-height: 80px;
  align-items: center;
  display: flex;
}

.navigation_button-wrapper {
  min-height: 60px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.navigation-link {
  font-size: 1.2rem;
  margin-right: 8;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.navigation-link::before{
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $white;
  visibility: "hidden";
  transition: all 0.3s ease-in-out;
}

.navigation-link.scroll-background::before {
  background-color: #0051b6; /* Or any other color */
}

.navigation-link:hover::before{
visibility: visible;
  width: 100%;
}

.navigation-active {
  text-decoration: none;
  color: $secondary-color;
}

.navigation-active::before{
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $quaternary-color;
  transition: all 0.3s ease-in-out;
  width:100%;
}

.navigation-list {
  display: flex;
  gap: 1.8rem;
  font-size: 0.9rem;
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}


// .w-nav {
//   z-index: 1000;
//   background: #ddd;
//   position: relative;
// }

  .home-content {
    position:absolute; 
    width:100%; 
    top:100%; 
    height: 100px;
  }

  .home-strapline {
    position: absolute;
    color: $white;
    z-index: 2;
    margin-right: 10vw !important;
    margin-left: 15vw !important
  }

  .home-valueprop-strapline {
    background-color: rgba(178, 175, 233, 0.152); /* Slightly transparent background to see the blur effect */
    //border: 1px solid rgba(255, 255, 255, 0.188); /* Thin white outline */
    border-radius: 5px; /* Rounded corners */
    padding: 2px; /* Adds space inside the box */
    padding-left: 5px;
    backdrop-filter: blur(6px); /* Blur effect */
    -webkit-backdrop-filter: blur(6px); /* For Safari */
  }
  

  .home-card-container {
    width: 100%;
  //height: 100vh;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  }

  .home-card {
    width: 200px;
    color: $secondary-color !important;
    background: rgba(228, 228, 228, 0.3) !important;
    border-radius: 25px !important;
    padding: 15px;
    position: fixed;
    top: 5px;
    bottom: 15px;
  
    @media (min-width: 768px) {
      align-self: flex-end;
    }
  
    @media (max-width: 767px) {
      margin: 0 auto; // Center the card on smaller screens
    }
  }

  .home-secondline {
    position: relative;
  }

.home-background {
    padding-top: 10px;
    padding-bottom: 50px;
    background: linear-gradient(to top, $gradient-colors);
}


.rolling-banner {
  overflow: hidden;
  height: 130px;
  position: relative;
}

.rolling-banner p {
  white-space: nowrap;
  line-height: 100px;
  font-size: 35px;
  color: $quaternary-color;
  animation: roll 30s linear infinite;
}

@keyframes roll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.home-banner {
  background-color: lightgrey;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.carousel-card {
  min-height: 9rem;
  align-content: center;
  padding: 1rem;
  margin: 0;
  width: 90% !important;

  p {
    margin: 0 !important;
  }

}


.green-tick-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.green-tick-list li {
  font-size: 1.2rem;
  display: block;
  min-width: 100%;
  line-height: 1;
  cursor: auto;
  text-decoration: none;
}

.green-tick-list li::before {
  content: '\2713'; /* Unicode character for a checkmark */
  display: inline-block;
  width: 1em;
  margin-right: 5px;
  color: green;
  font-size: 1.2em;
}


.secondary-background {
    //padding-top: 150px;
    background-image: linear-gradient(to right, $gradient-colors-green);
}

.global-nav {
  background-image: linear-gradient(to right, $gradient-colors-green);
  // padding: 8px 0;
}

.home-primary-section {
    padding-top: 70px;
    background-color: $background-color;
    border-radius: 15px;
    box-shadow: 0px 0px 20px;
    margin: 50px;
}

.quote-background {
    padding: 10px;
    background: linear-gradient(to left, #8a7ae8, #95a7e3);
}

.highlight-box {
  box-shadow: 15px 15px 0px $tertiary-color;
  background: linear-gradient(to bottom, #18fda1, #6cf3ff) !important;
}

.process-cards {
  box-shadow: 5px 5px 5px $quaternary-color !important;
}

.process-cards-number {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    //background-color: #fff;
    //border: 2px solid #007bff; /* Replace with your actual primary color */
    border-radius: 50%;
    padding: 5px;
    z-index: 1;
    color: $quaternary-color; /* Replace with your actual primary color */
    font-size: 40px;
    font-weight: bold;
    text-shadow: 0 0 10px $primary-color;

  }
  
.steps-icon {
    font-size: 50px;
    color: $quaternary-color;
}

.free-tier-price {
  border-color: $tertiary-color !important;
  border-width: 5px !important;
  //box-shadow: 5px 5px 5px $tertiary-color !important;
}

.calculated-price {
  background-color: $quaternary-color;
  color: #b4b4b4 !important;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 25px; // Smooth rounded edges
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1); // Light shadow effect
}

.purple-gradient-font {
  background: linear-gradient(135deg, $secondary-color, $quaternary-color 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  //display: inline-block; // Ensures gradient applies correctly
}

.paid-tier-price {
  border-color: $bright-green !important;
  border-width: 5px !important;
  max-width: 350px;
  //box-shadow: 5px 5px 5px $bright-green !important;
}

.headline-figure {
  font-size: 70px;
  margin-bottom: 0.5rem;
}

.headline-figure-subtitle {
  font-size: 40px;
}

.home-secondary-section {
    margin-top:20px;
    padding-top: 20px;
    padding-bottom:20px;
    //background-color: $secondary-color;
    //color: $primary-color;
}

// Accordion styling

.accordion-item {
    border: 1px solid $grey;
    border-radius: 15px; /* Adjust the radius as needed */
    margin-bottom: 10px; /* Set the margin between accordion items */
  }

// Initiate / Results styling

.full-width-primary-section {
    margin-top: 0px;
    padding-top: 10px;
    background-color: $background-color;
    //border-radius: 15px;
    //box-shadow: 0px 0px 20px;
    position: relative;
    min-height: 90vh;
}

.full-width-white-section {
  margin-top: 0px;
  padding-top: 5px;
  background-color: white;
  //border-radius: 15px;
  //box-shadow: 0px 0px 20px;
  position: relative;
  min-height: 7vh;
}

.full-width-white-bottom-section {
  margin-top: 0px;
  padding-top: 10px;
  background-color: white;
  //border-radius: 15px;
  //box-shadow: 0px 0px 20px;
  position: relative;
  //min-height: 10vh;
  border-bottom: 1px solid #007bff25;
}

.full-width-grey-section {
  margin-top: 0px;
  padding-top: 10px;
  background-color: $background-color;
  //border-radius: 15px;
  //box-shadow: 0px 0px 20px;
  position: relative;
  min-height: 90vh;
}

.full-width-lt-blue-section {
  margin-top: 0px;
  padding-top: 10px;
  background-color: #F8F8FF;
  //border-radius: 15px;
  //box-shadow: 0px 0px 20px;
  position: relative;
  min-height: 79vh;
}

.full-width-error-section {
  background-color: $background-color;
  min-height: 70vh;
}

@keyframes shadowAnimation {
    0% {
      box-shadow: 1px 1px 20px $secondary-color;
    }
    50% {
      box-shadow: 1px 1px 20px $quaternary-color;
    }
    100% {
      box-shadow: 1px 1px 20px $secondary-color;
    }
  }

.rounded-box {
    margin-top: 0px;
    padding: 20px;
    border-color: $grey;
    border-width: 2px;
    border-radius: 5px;
    background: $white;
    box-shadow: 1px 1px 3px $grey;
    //min-height: 400px;
}

.rounded-box-small {
  margin-top: 0px;
  padding: 20px;
  border-color: $grey;
  border-width: 2px;
  border-radius: 5px;
  background: $white;
  box-shadow: 1px 1px 3px $grey;
}

.light-bordered-box {
  margin-top: 20px;
  margin-bottom: 40px;
  //box-shadow: 3px 3px 5px $quaternary-color;
  background: rgba(248, 253, 253, 0.666) !important;
  //background: linear-gradient(to bottom, #dbe9f656, #d7f7f949) !important;
  font-size: medium !important;
  color: $secondary-color !important;
  border: 2px solid $secondary-color !important;

}

.bright-purple-font {
  color: #6d14d9
}

.roi-summary-box {
  padding-top: 5px;
  margin-top: 20px;
  margin-bottom: 40px;
  background-color: #c6bdf56f;
  //background: linear-gradient(to bottom, #63fed8, #606df9) !important;
  font-size: medium !important;
  color: #6d14d9 !important;
  border-color: white !important;
  border-radius: 10px !important;
}

.dashed-box {
    margin-top: 20px;
    padding: 20px;
    border: 2px dashed #ccc;
    border-radius: 10px;
    text-align: center;
}

.custom-nav-link {
  background-color: white !important;
  color: black !important;
  border-radius: 5px !important;
  border: 0.5px solid rgba(128, 128, 128, 0.23) !important;
}

.custom-nav-link.active {
  background-color: $secondary-color !important;
  color: white !important;
}

.custom-nav-link.active:hover {
  background-color: $secondary-color !important;
}

.custom-nav-link:hover {
  //font-weight: bold !important;
  background-color: $really-light-grey !important;

}

.clickable-summary {
  cursor: pointer;
}

.clickable-summary:hover {
  background-color: #f0f0f0; /* Change this to your desired hover background color */
}

.file-upload-label {
  color: #007bff; /* Hyperlink color */
  cursor: pointer;
}
  
  // 

.file-table-container {
    width: 100%;
    margin-top: 30px;
    padding: 30px 50px;
    table-layout: fixed;
    border-color: $tertiary-color;
    border-width: 10px;
    border-radius: 10px;
    border-collapse: collapse;
  
    colgroup {
      col {
        &:nth-child(1) {
          width: 5%; // New column for checkboxes
        }
        &:nth-child(2) {
          width: auto;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 10%;
        }
        &:nth-child(5) {
          width: 20%; // Additional column for folder name
        }
      }
    }
  
    thead {
      tr {
        background-color: $white;
        border-radius: 10px;
  
        th {
          text-align: center;
          padding: 8px 16px;
  
          &:first-child {
            text-align: center;
            input {
              margin-right: 4px;
            }
          }
        }
      }
    }
  
    tbody {
      tr {
        background-color: #f0f0ff;
        border-radius: 10px;
  
        &:nth-child(even) {
          background-color: $white;
        }
  
        td {
          text-align: center;
          padding: 8px 16px;
        }
      }
    }
  }

  .border-right {
    border-right: 1px solid #ccc; /* Adjust the color and thickness as needed */
  }

  .border-left {
    border-left: 1px solid #ccc; /* Adjust the color and thickness as needed */
  }

  .code-editor-hidden {
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease, height 0.5s ease;
  }
  
  .code-editor-visible {
    opacity: 1;
    height: auto;
    transition: opacity 0.5s ease, height 1s ease;
  }

  .overlay-container {
    position: fixed;
    bottom: -100px; /* Start position for slide-in */
    right: 20px;
    width: auto;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    cursor: pointer;
    transition: bottom 0.3s ease-in-out; /* Transition for sliding */
  }
  
  .overlay-container.slide-in {
    bottom: 20px; /* End position for slide-in */
  }
  
  .overlay-container.slide-out {
    bottom: -100px; /* End position for slide-out */
  }
  
  .overlay-content {
    color: $secondary-color;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .overlay-close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .overlay-mail-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .overlay-content p {
    margin: 0;
    font-size: 16px;
  }

  /* Add this CSS to limit the modal size and enable scrolling inside the modal body */
.scrollable-modal-dialog {
  max-height: 90vh; /* Limits the modal height to 90% of the viewport height */
}

.scrollable-modal-body {
  overflow-y: auto; /* Enables vertical scrolling when content overflows */
  flex: 1 1 auto;   /* Allows the body to expand and take up available space */
  max-height: 60vh; /* Restrict the body height to make room for header/footer */
}
        
.custom-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .d-flex {
    display: flex;
    align-items: center;

    img {
      border-radius: 50%;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  // Dropdown list styles
  .dropdown-list {
    position: absolute;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 200px; // Set a width for dropdown
    border-radius: 5px;
    margin-top: 5px; // Space between current org display and dropdown
    transition: opacity 0.3s ease;
    opacity: 1; // To add transition effect
    overflow: hidden;

    // Dropdown item styles
    .dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 10px;
      border-bottom: 1px solid #f1f1f1; // Optional styling for dropdown items
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #f9f9f9; // Hover effect for dropdown items
      }

      img {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1); // Slightly enlarge logo on hover
        }
      }

      span {
        font-size: 14px; // Font size for organization name
        color: #333; // Text color
        transition: color 0.2s ease;

        &:hover {
          color: #007bff; // Change color on hover
        }
      }
    }
  }
}

.dynamic-table-section {
  .dynamic-table-search {
    margin-left: 12px;
    margin-top:6px;
    margin-bottom: 0.2rem; // Space below the search input
    border-radius: 10px;
  }

  .dynamic-table-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; // Center the spinner vertically
  }

  .table-responsive {
    overflow-x: auto;
  }

  .dynamic-table {
    width: 98%;
    border-collapse: separate; // Use separate borders for spacing
    border-spacing: 0 1rem; // 1rem vertical spacing between rows
    margin-left: 10px;

    max-height: 500px; /* Adjust to desired max height */
    overflow-y: auto; /* Enable vertical scrolling */
    //display: block;

    th.dynamic-table-header {
      background-color: transparent;
      padding: 10px;
      cursor: pointer;
      border: none;
      color: #6c757d;
      font-size: 14px;
      font-weight: normal;

      &:hover {
        background-color: transparent;
      }
    }

    tr {
      transition: background-color 0.3s ease;
      border: none;

      &:hover {
        background-color: transparent;
      }
    }

    td {
      padding: 12px; // Increased padding for cells
      border: none; // No border for cells
      height: 50px; // Increased height for table rows

      &:last-child {
        border-right: none; // Remove right border on last cell
      }

      // Apply primary color to the first column cells
      &:nth-child(1) {
        color: $secondary-color; // Use the primary color for the first column
      }
    }
  }

  .dynamic-table-row {
    vertical-align: middle !important;
    background-color: #fff;
    border-radius: 8px;
    font-size: 16px;
    box-shadow: 5px 3px 6px 2px rgba(110, 98, 213, 0.227);
    transition: background-color 0.3s ease;
    overflow: hidden;

    &:hover {
      background-color: #f1f1f1;
    }
  }

  .dynamic-table-row td:first-child {
    border-top-left-radius: 8px; /* Rounded top-left border for the first cell */
    border-bottom-left-radius: 8px; /* Rounded bottom-left border for the first cell */
  }
  
  .dynamic-table-row td:last-child {
    border-top-right-radius: 8px; /* Rounded top-right border for the last cell */
    border-bottom-right-radius: 8px; /* Rounded bottom-right border for the last cell */
  }
  

  // Alerts for empty data
  .alert {
    margin-top: 1rem;
  }
}

.nav {
  border-bottom: none !important;
}

.nav-tabs .nav-link {
  border: none;
  background: none;
  color: #6c757d;
  margin-right: 10px;
  padding-bottom: 16px;
}

.nav-tabs .nav-link:hover {
  //color: #007bff; /* Optional hover color (Bootstrap's primary color) */
  //border: none !important;
}

/* On select: apply secondary-color text and primary-color border */
.nav-tabs .nav-link.active {
  color: $secondary-color !important;
  border-bottom: 2px solid $secondary-color !important;
  border: none;
}

.badge.bg-primary {
  background-color: $secondary-color !important; // Your desired color
  color: white !important; // Adjust text color for contrast
  border-color: #B0E0E6 !important; // Optional: ensure border color matches background
}

.badge.bg-secondary {
  background-color: #cceef5 !important; // Your desired color
  color: rgb(81, 81, 81) !important; // Adjust text color for contrast
  border-color: #B0E0E6 !important; // Optional: ensure border color matches background
}

.badge.bg-success {
  background-color: #2bca3e !important; // Your desired color
  color: white !important; // Adjust text color for contrast
  border-color: #B0E0E6 !important; // Optional: ensure border color matches background
}

.badge.bg-warning {
  background-color: #a981ff !important; // Your desired color
  color:white !important; // Adjust text color for contrast
  border-color: #B0E0E6 !important; // Optional: ensure border color matches background
}

.badge.bg-info {
  background-color: $tertiary-color !important; // Your desired color
  color:white !important; // Adjust text color for contrast
  border-color: #B0E0E6 !important; // Optional: ensure border color matches background
}

/* Global styles or component-specific CSS */
.CodeMirror {
  font-size: 14px; /* Adjust font size as needed */
}

// Initiate workspace

.initiate-card-hover {
  transition: transform 0.2s, box-shadow 0.2s;
}

.initiate-card-hover:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.initiate-active-card {
  border: 2px solid #00ff00; /* Example for the selected state */
}

.list-group-item {
  color: black;
  border: none; // Remove borders
  border-radius: 0;                // Optional: Ensures no rounded edges if desired
}

// Hover state
.list-group-item:hover {
  background-color: $really-light-grey !important;
  color: black !important;
  border: none; // Ensure border is removed on hover
}

// Active/click state
.list-group-item.active,
.list-group-item:focus,
.list-group-item.active:focus {
  background-color: $secondary-color !important;
  color: white !important;
  border: none; // Ensure border is removed on active/focus
}

.excel-container {
  border: 2px dashed green;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 150px;
}

.excel-icon-overlay {
  position: absolute;
  top: -12px;
  right: -11px;
  background-color: green;
  border: 2px solid green;
  border-radius: 20%;
  padding: 2px;
  padding-bottom: 4px;
  font-size: 20px;
  color: white;
  width: 22px;
  height: 25px; /* Set a fixed height */
  display: flex;
  align-items: center;
  justify-content: center; /* Center the icon within the box */
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
  cursor: pointer;
  font-size: 30px;
}

.icon-button:hover {
  color: darkgreen;
}

.nav-pills .nav-link.active {
  background-color: $secondary-color !important;
}

.nav-pills .nav-link:hover {
  background-color: lightgrey;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  right: 0;
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 8px;
  width: 12px;
  height: 12px;
}

/* General card styling */
.dynamic-card {
  width: 100%; /* Ensure card takes up the full width of its container */
  flex: 1 1 calc(33.333% - 16px); /* Default to 3 cards per row */
  max-width: calc(33.333% - 16px); /* Max width for each card */
  border-radius: 8px;
  padding: 1px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
}

/* Hover effect on the card */
.dynamic-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Card content styling */
.card-content {
  margin-bottom: 10px;
}

/* Status badge positioning */
.status-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

/* Grid container for the cards */
.dynamic-cards-grid {
  display: flex;
  flex-wrap: wrap; /* Ensure cards wrap onto new rows */
  gap: 16px; /* Gap between cards */
  justify-content: space-between; /* Distribute cards evenly */
}

/* Media query for tablet-size screens (768px or below) */
@media (max-width: 768px) {
  .dynamic-card {
    flex: 1 1 calc(50% - 16px); /* 2 cards per row on medium screens */
    max-width: calc(50% - 16px); /* Max width for 2 cards */
  }
}

/* Media query for mobile-size screens (480px or below) */
@media (max-width: 480px) {
  .dynamic-card {
    flex: 1 1 100%; /* 1 card per row on small screens */
    max-width: 100%; /* Max width for 1 card */
  }
}

/* Animation for fading in cards */
.dynamic-card.fade {
  animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.vis-network {overflow:visible;}

.blue {
  color: #224aad;
}

.carousel-container-padding-40-px {
  padding-top: 40px;
  padding-bottom:30px;
}

.react-multiple-carousel__arrow--right {
  right: calc(50% - 50px) !important;
  top: 0px
}

.react-multiple-carousel__arrow--left {
left: calc(50% - 50px) !important; 
top: 0px
}

/* Add a gap between progress bars */
.progress-bar:not(:last-child) {
  margin-right: 2px; /* Adjust gap size as needed */
  border-radius: 6px;
}

/* Ensure empty bars are hidden */
.progress-bar[style*="width: 0%"] {
  display: none; /* Hides bars with 0 width */
}

/* On hover, slightly grow and add shadow */
.progress-bar:hover {
  margin: 2px
}

/* Animation when progress bar is clicked */
.progress-bar:active {
  animation: click-animation 0.3s ease-in-out;
  background-color: #0051b6; /* Optional: Change color when clicked */
}

/* Keyframes for click animation */
@keyframes click-animation {
  0% {
    transform: scale(1); /* Start slightly enlarged */
  }
  50% {
    transform: scale(0.95); /* Slightly shrink in the middle */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

n8n-demo {
  --n8n-workflow-min-height: calc(100vh - 250px);
  --n8n-iframe-border-radius: 30px;
}