// Fonts
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');
$primary-font: 'Outfit', sans-serif;

// Colors
$primary-color: #ffffff;
$secondary-color: #4a4bd6;
$tertiary-color: #FF8962;
$quaternary-color: #6f58ed;
$chilled-purple: #cbc2fa;
$chilled-green: #82F5D2;
$bright-green: #1bf9d5;
$background-color: ghostWhite;
$light-purple: #dbd6ff;
$grey: #343434;
$light-grey: #5e5e5e;
$really-light-grey: #efefefb9;
$red: red;
$white: #ffffff;
$success: #29bf12;
$success-shadow: #e44400b4;

$gradient-colors: #0a3692, #284aa3, #3e5fb3, #5374c3, #688ad3, #6c8edc, #7093e4, #7497ed, #6b89f0, #677bf1, #696bf0, #6f58ed;
$gradient-colors-btn-primary:  #6f58ed, #5d52dc, #4b4ccb, #3946ba, #273fa9, #194eb3;
$gradient-colors-btn-tertiary:  #FF8962, #e44400;
$gradient-colors-btn-success:  #29bf12, #1bf9d5;
$gradient-colors-contact: $light-purple, #8570ff,;


$gradient-colors-green: #6f58ed, #5d52dc, #4b4ccb, #3946ba, #273fa9, #194eb3, #085dbc, #006bc3, #0092e0, #00b7ea, #00d9e4, #1bf9d5;
